import React, {Component} from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Highlight from '../Components/Highlight/Highlight';
import Building from '../img/buildingAlexa.png'

class Alexa extends Component {

    constructor(...props) {
        super(...props);
        this.state = {}
    }

    render() {
        return (

            <div>
                <div className="container-fluid px-0">
                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <Header/>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="row my-100 mx-0">
                        <div className="col-12 px-0">
                            <Highlight title="Estimado Cliente" content="Es un gusto informarle que hemos lanzado el servicio de Noticias de NSC Asesores en Amazon Alexa,  seguimos innovando para estar más cerca de usted." pa="Instrucciones para utilizar por primera vez su App Amazon Alexa." pb="◉ Instalar desde la Play Store o la App Store la aplicación de Amazon Alexa." pc="◉ Registrar en la aplicación de Amazon Alexa." pd="◉ Para escuchar las noticias instalar el skill de Noticias NSC Asesores, para esto:" pe="◉ Presionar el icono o di: Alexa." pf="◉ Decir la frase: Alexa, activa skill." pg="◉ Responder: Noticias NSC Asesores." ph="✔ Después de esto podrá escuchar cada que quiera las noticias de NSC con la frase Alexa, abre noticias NSC Asesores." pi="Así mismo le hacemos llegar unos vídeo tutoriales para utilizar el skill de NSC en Amazon Alexa."
                                image={Building}></Highlight>
                        </div>
                    </div>

                    <div className="container">
                        <table className='table'>
                            <center>
                                <p className="title">Vídeo Tutoriales</p>
                            </center>
                            <tr>
                                <th>
                                    <div className="col-12">

                                        <a className="btn btn-ttc" href="https://youtu.be/GqA7rCvwZno" target="ventanita" onclick="window.open('', 'newwindow','scrollbars=yes,titlebar=yes', 'width=500,height=500')">
                                        ▷ Instalación
                                        </a>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="col-12">

                                        <a className="btn btn-ttc" href="https://youtu.be/_E4DGS6wVoE" target="ventanita" onclick="window.open('', 'newwindow','scrollbars=yes,titlebar=yes', 'width=500,height=500')">
                                        ▷ Lectura de Noticias
                                        </a>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="col-12">

                                        <a className="btn btn-ttc" href="https://youtu.be/dIt6oBfLAWU" target="ventanita" onclick="window.open('', 'newwindow','scrollbars=yes,titlebar=yes', 'width=500,height=500')">
                                        ▷ Cierre de mercados
                                        </a>
                                    </div>
                                </th>
                            </tr>
                        </table>
                        <p>Esperamos que este servicio le sea de gran utilidad.</p>
                        <p>Cualquier duda o comentario estamos a sus órdenes en <b>nscalexa@nscasesores.com</b></p>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>

                    {/*
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Header />
            </div>
          </div>

          <Hero
            page="-video"
            content="" />


          <div className="container">
            <div className="row my-50 mx-0 d-flex justify-content-center">
              <div className="col-12 col-lg-8">
                <iframe title="1" width="100%" height="315" src="https://www.youtube.com/embed/FCwEzdlEFpM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                <p className="title">Menor producción de crudo</p>
                <p className="mb-30">La caída en la producción de crudo está impactando en los ingresos petroleros, y si no se revierte se dificultará el cumplimiento de las metas fiscales para el presente año y podría propiciar nuevos recortes al gasto.</p>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="2" className="w-100" src="https://www.youtube.com/embed/f_c1QCPDlfE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">¿Cómo funciona la bolsa mexicana de valores?</p>
                  <p className="mb-30">Te haz preguntado, ¿Como funciona la BMV, que es, y como se invierte? en este video te queremos trasmitir la idea, de lo que es, sus funciones y regulaciones de la Bolsa de Valores de México.</p>
                </div>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="3" className="w-100" src="https://www.youtube.com/embed/skK1-4Ss6bI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">Introducción al Mundo de las Inversiones</p>
                  <p className="mb-30">Aprende sobre el mundo de las inversiones, descubre sus beneficios y aplica estos conocimientos para ser un elemento...</p>
                </div>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="4" className="w-100" src="https://www.youtube.com/embed/JCMYjez93pk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">¿Cómo prepararte para una recesión económica?</p>
                  <p className="mb-30">Descubre los pasos necesarios para preparate en caso de una crisis económica mundial, no dejes que este tipo de situaciones...</p>
                </div>
              </div>
            </div>
          </div >

          <div className="container my-100">
            <div className="row">
              <div className="col-12">
                <p className="title d-flex justify-content-center">Fuentes Financieras</p>
              </div>
            </div>


            <div className="row">
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoWS} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoTE} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoFT} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoHA} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoCNN} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoBB} alt="Wall Street Journal Logo" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row my-100">
              <div className="col-12">
                <p className="title text-center">Las 6 de NSC</p>
                <p className="text-center">Entérate de las últimas y más relevantes noticias de ámbito financiero.</p>
              </div>
            </div>
            <div className="row mx-0 mb-30">
              <div className="col-12">
                <NscSix />
              </div>
            </div>
          </div>

*/}


                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Alexa;
