import React, {Component} from 'react';
import NscSixDetail from '../Nsc-six-detail/Nsc-six-detail';

const NSC_NEWS_URL = process.env.REACT_APP_NSC_NEWS_URL 

class NscSix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }
    }

    componentDidMount = () => {
        this.getNscSix();
    }

    getNscSix = () => {
        fetch(NSC_NEWS_URL)
        .then(rs => rs.json())
        .then(response => {
          this.setState({news: response})
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <div>
                <div id="carouselExampleIndicators" className="carousel slide nsc-six d-none d-lg-block" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                {
                                this.state.news.slice(0, 3).map((news, index) => {
                                    let number = `${
                                        index + 1
                                    }`;
                                    return (
                                        <div key={index} className="col-12 col-lg-4">
                                            <NscSixDetail customClass="mb-30"
                                                number={number}
                                                content={news}/>
                                        </div>
                                    )
                                })
                            } </div>
                        </div>

                        <div className="carousel-item">
                            <div className="row">
                                {
                                this.state.news.slice(3, this.state.news.length).map((news, index) => {
                                    let number = `${
                                        index + 4
                                    }`;

                                    return (
                                        <div key={index} className="col-12 col-lg-4">
                                            <NscSixDetail customClass="mb-30"
                                                number={number}
                                                content={news}/>
                                        </div>
                                    )
                                })
                            } </div>
                        </div>
                    </div>

                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    </ol>
                </div>

                <div id="Indicators" className="carousel slide nsc-six d-lg-none" data-ride="carousel">
                    <div className="carousel-inner">
                        {
                        this.state.news.map((news, index) => {
                            let number = `${
                                index + 1
                            }`;

                            if (index === 0) {
                                return (
                                    <div key={index} className="carousel-item active">
                                        <div className="row">
                                            <div className="col-12">
                                                <NscSixDetail customClass="mb-30"
                                                    number={number}
                                                    content={news}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index} className="carousel-item">
                                        <div className="row">
                                            <div className="col-12">
                                                <NscSixDetail customClass="mb-30"
                                                    number={number}
                                                    content={news}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    } </div>

                    <ol className="carousel-indicators">
                        <li data-target="#Indicators" data-slide-to="0" className="active"></li>
                        <li data-target="#Indicators" data-slide-to="1"></li>
                        <li data-target="#Indicators" data-slide-to="2"></li>
                        <li data-target="#Indicators" data-slide-to="3"></li>
                        <li data-target="#Indicators" data-slide-to="4"></li>
                        <li data-target="#Indicators" data-slide-to="5"></li>
                    </ol>
                </div>
            </div>
        );
    }
}

export default NscSix;
