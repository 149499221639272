import React, { Component } from 'react';

class CarouselComponent extends Component {

  render() {
    return (
      <div className="home-carousel">
        <div id="carouselExampleIndicatorsHome" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner home">
            <div className="carousel-item active">
              <div className="content -a">
                <div className="textWrapp h-100">
                  <h1 className="h1 text-center c-white w-100">La primera empresa de Asesoría Financiera Independiente en México</h1>
                  <a href="/about" target="blank"><button className="btn -outlineWhite mt-30">Leer más</button></a>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="content -b">
                <div className="textWrapp h-100">
                  <h1 className="h1 text-center c-white w-100">Empresa líder en el manejo y asesoría de patrimonios</h1>
                  <a href="/about" target="blank"><button className="btn -outlineWhite mt-30">Leer más</button></a>
                </div>
              </div>
            </div>
          </div>

          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicatorsHome" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicatorsHome" data-slide-to="1"></li>
          </ol>
        </div>
      </div>
    );
  }
}
export default CarouselComponent;