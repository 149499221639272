import React, { Component } from 'react';

class NoticeCard extends Component {
  constructor(...props) {
    super(...props);
    this.state = {}
  }

  render() {
    let d = this.props.date.match(/(\d*)-(\d*)-(\d*)T.*/);

    return (
      <div className={`notice-card ${this.props.customClass}`}>
        <img src={this.props.image} alt={this.props.alt} />
        <div className="__header">
          {this.props.imageAuthor
          ? <p>Foto: {this.props.imageAuthor}</p>
          : <p>Foto del editor</p>}
          <p className="date px mb-0">{d[3]}/{d[2]}/{d[1]}</p>
        </div>
        {this.props.showtitle === true &&
          <p className="subtitle mb-0">{this.props.title}</p>
        }
        <p className="content mb-0">{this.props.content}</p>
        <div className="link">
          <a href={this.props.link} rel="noopener noreferrer" target="_blank">Leer más</a>
        </div>
      </div>
    );
  }
}

export default NoticeCard;