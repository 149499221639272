import React, {Component} from 'react';
import {TabPane} from 'reactstrap';
import Header from '../Components/Header/Header'
import NscSix from '../Components/Nsc-six/Nsc-six'
import Highlight from '../Components/Highlight/Highlight'
import Footer from '../Components/Footer/Footer'
import TextImage from '../Components/TextImage/TextImage'
import NoticeCard from '../Components/NoticeCard/NoticeCard'
import CarouselComponent from '../Components/Carousel/Carousel'

import Building from '../img/building.jpg'
import ActionImage from '../Components/Action-image/Action-image'
import ActionA from '../img/action-a.jpg'
import ActionB from '../img/action-b.jpg'
import ActionC from '../img/action-c.jpg'
import ActionE from '../img/action-e.jpg'
import ActionF from '../img/action-f.jpg';

import marco from '../pdf/marcoGeneral.pdf';
import guia from '../pdf/guia.pdf';


class Home extends Component {
    constructor(...props) {
        super(...props);
        this.state = {
            newsItems: []
        }
    }

    componentDidMount = () => {
        this.getNews();
    }

    async getNews() {
        this.setState({loading: true, error: null});


    }

    render() {
        return (
            <div>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12">
                            <Header/>
                        </div>
                    </div>
                    <CarouselComponent/>

                    <div className="container my-100">
                        <div className="row mb-30 mt-30">
                            <div className="col-12">
                                <p className="title text-center">
                                    Las 6 de NSC</p>
                                <p className="text-center">Últimas y más relevantes noticias de ámbito financiero.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NscSix/>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <div className="container">
                        <div className="row mt-50">
                            <div className="col-12 my-50">
                                <p className="title text-center">
                                    Las&nbsp;<b>6</b>&nbsp;de NSC</p>
                                <p className="text-center"></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p><NscSix/></p>
                            </div>
                        </div>
                    </div>
*/}
                    <div className="row my-100 mx-0">
                        <div className="col-12 px-0">
                            {/*<Highlight title="NSC Asesores" content="Somos una empresa de asesoría en inversiones independiente fundada en 1987, registrada ante la CNBV; cuya misión radica en ofrecer a nuestros clientes asesoría, a través de un equipo altamente capacitado, cuyos valores son la honestidad, confidencialidad, prudencia, profesionalismo e independencia de criterio." contentBold="Al ser independientes evitamos cualquier conflicto de interés." contentC="Somos la firma independiente con mayor cantidad de activos manejados en México."*/}
                            <Highlight title="NSC Asesores" contentSS="NSC Asesores" content=" es una de las firmas independientes de asesoría financiera más antiguas en nuestro país.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    Desde sus inicios en 1987 se ha especializado en el manejo de asesoría y patrimonios, hasta convertirse en una de las firmas lideres en el Mercado." contentD="Contamos con un grupo de profesionales de diferentes generaciones con la experiencia y recursos necesarios para asesorar y manejar el patrimonio de nuestros clientes. Manejamos todo tipo de inversiones líquidas, operaciones sofisticadas en los mercados financieros, incluyendo instrumentos de deuda, acciones cotizadas en las Bolsa Valores, sociedades de inversión, instrumentos indizados al tipo de cambio y la inflación, opciones, warrants, Exchange Traded Funds, así como una serie de productos alternativos (bienes raíces, private equities, etc.)"
                                image={Building}></Highlight>

                            {/*<Highlight title="NSC Asesores" content="
                                                                                                    NSC Asesores es una de las firmas independientes de asesoría financiera más antiguas en nuestro país.
                                                                                                    Desde sus inicios en 1987 se ha especializado en el manejo de asesoría y patrimonios, hasta convertirse en una de las firmas lideres en el Mercado.
                                    
                                                                                                    
                                    "
                                                                                                    
                                                                                                    contentB="NSC Asesores cuenta con un grupo de profesionales con la experiencia y los recursos necesarios para asesorar y manejar el patrimonio de sus clientes."
                                                                                                    center="NSC Asesores maneja todo tipo inversiones líquidas, operaciones sofisticadas dentro del sector bursátil y bancario, incluyendo instrumentos de deuda, acciones cotizadas en la bolsa de valores, Sociedades de Inversión, instrumentos indexados al tipo de cambio y la inflación, opciones, warrants y Exchange traded funds, entre otras."
                                                                                                    
                                image={Building}/> */} </div>
                    </div>
                    {/*  contentBB="NSC Asesores" 
                                                                                                                                     contentB=" cuenta con un grupo de profesionales con la experiencia y los recursos necesarios para asesorar y manejar el patrimonio de sus clientes." 
                                                                                                                                 */}

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-12 d-flex flex-column justify-content-center ">
                                <p className="title">Nuestros Servicios</p>
                                <p className="mb-30">
                                    <b>NSC Asesores&nbsp;</b>, S.C., Asesor en inversiones independiente sabemos que realizar inversiones acertadas en un ámbito económico tan complejo y con un ritmo de cambio tan acelerado como el que vivimos hoy en día, exige una orientación profesional mucho más especializada que los servicios tradicionales que prestan la instituciones financieras.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <ActionImage customClass="mb-30"
                                    img={ActionB}
                                    title="Gestión de inversiones"
                                    link="/services#management"/>
                            </div>
                            <div className="col-12 col-lg-6">
                                <ActionImage customClass="mb-30"
                                    img={ActionA}
                                    title="Asesoría de inversiones"
                                    link="/services#advisory"/>
                            </div>
                            <div className="col-12 col-lg-6">
                                <ActionImage customClass="mb-30"
                                    img={ActionF}
                                    title="Recepción y transmisión de órdenes."
                                    link="/services#reception"/>
                            </div>
                            <div className="col-12 col-lg-6">
                                <ActionImage customClass="mb-30"
                                    img={ActionC}
                                    title="Family Office"
                                    link="/services#family-office"/>
                            </div>
                            <div className="col-12 col-lg-6">
                                <ActionImage customClass="mb-30"
                                    img={ActionE}
                                    title="Inversiones en Capital privado"
                                    link="/services#investment"/>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 mt-100">
                        <div className="col-12 px-0">
                            <div className="text-image"></div>
                            <TextImage customClass="-investment mb-30" title="¿Qué nos preguntan?" content="Las preguntas y respuestas más frecuentes" link="/FAQ"/>
                        </div>
                    </div>
                    {/*<div className="container">
                        <div className="container-fluid">
                            <h1>Noticias</h1>
                        </div>
                        <TabPane tabId="1">
                            <div className="row my-50">
                                {
                                this.state.newsItems.map((news, index) => <div key={index}
                                    className="col-lg-4 col-md-6 col-12 d-flex mb-20">
                                    <NoticeCard 
                                        image={news.image}
                                        date={news.date}
                                        content={news.title}
                                        link={news.link}
                                        imageAuthor={news.imageAuthor}/>
                                        
                                </div>)
                            } </div>
                        </TabPane>
                    </div> */}
                   
                        <div className="container">
                          

                            <table className='table'>

                           <center><p className="title">Documentos Adjuntos</p></center> 
                                <tr>
                                    <th>
                                        <div className="col-12">
                                           
                                                <a className="btn btn-ttc"
                                                    href="https://functionsnscwebapp.blob.core.windows.net/images/Guia.pdf"
                                                    target="ventanita"
                                                    onclick="window.open('', 'newwindow','scrollbars=yes,titlebar=yes', 'width=500,height=500')">
                                                    Guía de Servicios.
                                                </a>
                                          
                                        </div>
                                    </th>
                                   
                                </tr>
                                <tr>

                                <th>
                                        <div className="col-12">
                                        
                                                <a className="btn btn-ttc"
                                                    href="https://functionsnscwebapp.blob.core.windows.net/images/MarcoGeneral.pdf"
                                                    target="ventanita"
                                                    onclick="window.open('', 'newwindow','scrollbars=yes,titlebar=yes', 'width=500,height=500')">
                                                    Marco General de Actuación.
                                                </a>
                                         
                                        </div>
                                    </th>

                                </tr>


                            </table>
                        </div>
                    
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Home;
