import React, { Component } from 'react';

class Highlight extends Component {

  render() {
    return (
      <div className="highlight-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 py-50 d-flex align-items-justify flex-column">
          <p className="title">    <center>{this.props.title}</center></p>
              <p><strong>{ this.props.contentSS } </strong>{ this.props.content }</p>       
              <p><strong>{ this.props.contentBB } </strong>{ this.props.contentB }</p>
              <p><strong>{ this.props.contentDD } </strong>{ this.props.contentD }</p>   


              <p><b>{this.props.a }{ this.props.pa }</b></p>   
              <p><strong>{ this.props.b } </strong>{ this.props.pb }</p> 
              <p><strong>{ this.props.c } </strong>{ this.props.pc }</p> 
              <p><strong>{ this.props.d } </strong>{ this.props.pd }</p>   
              <p><strong>{ this.props.e } </strong>{ this.props.pe }</p> 
              <p><strong>{ this.props.f } </strong>{ this.props.pf }</p> 
              <p><strong>{ this.props.g } </strong>{ this.props.pg }</p> 
              <p><strong>{ this.props.h } </strong>{ this.props.ph }</p> 
              <p><strong>{ this.props.i } </strong>{ this.props.pi }</p> 
         
              <p className="mb-auto mt-auto">{ this.props.center }</p>
              <p><b>{ this.props.contentBold }</b></p>
              <p>{ this.props.contentC }</p>
              { this.props.button === true &&
                <a href="#_" className="mb-auto"><button className="btn -outlineDark">Leer más</button></a>
              }
            </div>



            <div className="col-12 col-lg-6">
              <img className="w-100 d-none d-md-block" src={this.props.image} alt=""/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Highlight;
