import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PodcastCard from '../PodcastCard/PodcastCard';
import PodcastA from '../../img/podcast-a.jpg';



function CharacterCard(props) {
  const { character } = props;
  
  return (  
    <div className="CharacterCard" style={{      
      backgroundImage: `url(${character.image})` 
       
      }}>
      {character.imageAuthor
      ? <p className="foto-editor">Foto: {character.imageAuthor}</p>
      : <p className="foto-editor">Foto del editor</p>}
    <a href={character.link} rel="noopener noreferrer" target="_blank" >
    <div>
          <div className="CharacterCard__name-container">
          {character.title} Leer mas ...
          </div>
    </div>    
     </a> 
     </div> 
  );
}

const NSC_PUBLIC_API_URL = process.env.REACT_APP_NSC_PUBLIC_API_URL;

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      financiero_news: [],
      reuters_news: [], 
      persons: [], 
      loading: true,
    error: null,
    data: {
      newsItems: []
    },
    nextPage: 1
    }
  }

  componentDidMount = () => this.fetchCharacters();

  fetchCharacters = async () => {
    this.setState({ loading: true, error: null });

    try {
      const response = await fetch(
        `${NSC_PUBLIC_API_URL}/news/front-page?n=elfinanciero,eleconomista`
      );

      const {newsItems = []} = await response.json();

      this.setState({
        loading: false,
        data: { newsItems },
        nextPage: this.state.nextPage + 1
      });
    } catch (error) {
      this.setState({ loading: false, error: error });
    }
  };


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    };
  }

  render () {
    if (this.state.error) {
      return "Error!";
    }
    return (
      <React.Fragment>  

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              NOTICIAS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              PODCASTS
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row my-50">
              {          
                this.state.data.newsItems.map((character2, index) => {
                  return (
                    <div key={index} className="col-lg-4 col-md-6 col-12 d-flex mb-20">
                      <CharacterCard character={character2}/>
                    </div>
                  )
                }
              )}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row my-50">
              <div className="col-lg-3 col-md-6 col-12">
                <PodcastCard
                  customClass=" mb-20"
                  image={PodcastA}
                  title="Cuéntame de economía"
                  content="Economía para no economistas. Con un tono lúdico y con rigor se explica el tema económico…"
                  link="https://open.spotify.com/show/2G44OhAzSlNludsv2XPKJd" />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <PodcastCard
                  customClass=" mb-20"
                  image={PodcastA}
                  title="Cuéntame de economía"
                  content="Economía para no economistas. Con un tono lúdico y con rigor se explica el tema económico…"
                  link="https://open.spotify.com/show/2G44OhAzSlNludsv2XPKJd" />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <PodcastCard
                  customClass=" mb-20"
                  image={PodcastA}
                  title="Cuéntame de economía"
                  content="Economía para no economistas. Con un tono lúdico y con rigor se explica el tema económico…"
                  link="https://open.spotify.com/show/2G44OhAzSlNludsv2XPKJd" />
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <PodcastCard
                  customClass=" mb-20"
                  image={PodcastA}
                  title="Cuéntame de economía"
                  content="Economía para no economistas. Con un tono lúdico y con rigor se explica el tema económico…"
                  link="https://open.spotify.com/show/2G44OhAzSlNludsv2XPKJd" />
              </div>
            </div>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

export default News;